<script setup lang="ts">
import { useApiFetch } from "~/composible/useApiFetch";
import { useDevice } from "#imports";

const appConfig = useAppConfig();
const { isMobile } = useDevice();

const lists = {
  "top-editors-choice": "Editor’s Choice",
  "top-fantasy": "Top Fantasy",
  "top-lgbtq": "Top LGBTQ+",
  "top-romance": "Top Romance",
  "top-thriller": "Top Thriller",
  "top-trending": "Top Trending",
  "top-steamy-stories-18": "Top Steamy Stories (18+)",
  "top-urban": "Top Urban",
  "top-werewolf": "Top Werewolf",
  "top-young-adult": "Top Young Adult"
}

useSeoMeta({
  title: 'FictionMe – Read Thousands of Novels for free',
  titleTemplate: '%s',
  description: 'FictionMe provides online reading of romance novels, fiction novels, paranormal and various genres. Enter a world where you can read the stories and find the best romantic novel and alpha werewolf romance books worthy of your attention.',
  ogTitle: 'FictionMe – Read Thousands of Novels for free',
  ogDescription: 'FictionMe provides online reading of romance novels, fiction novels, paranormal and various genres. Enter a world where you can read the stories and find the best romantic novel and alpha werewolf romance books worthy of your attention.',
  twitterTitle: 'FictionMe – Read Thousands of Novels for free',
  twitterDescription: 'FictionMe provides online reading of romance novels, fiction novels, paranormal and various genres. Enter a world where you can read the stories and find the best romantic novel and alpha werewolf romance books worthy of your attention.'
});

const TRENDING_SLUG = 'trending';
const EDITORS_CHOICE_SLUG = 'editors-choice';

const { data, error, pending } = useApiFetch('/novels/top-lists', {
  transform: (data) => data.data,
});

const { data: reviews } = useApiFetch(`/reviews/recent`, {
  transform: (reviews) => reviews.data,
  lazy: true
});

const { data: posts } = useApiFetch(`/blog/recent`, {
  transform: (posts) => posts.data,
  lazy: true
});
</script>

<template>
  <div>
    <div class="slider bg-[#060A0F] bg-cover bg-no-repeat text-white py-20 max-md:py-7 relative">
      <div class="absolute top-0 left-0 w-full h-full z-0">
        <img alt="header img" :src="isMobile ? '/img/home-1-sm.webp' : '/img/home-1.webp'" class="w-full h-full object-cover object-center">
      </div>

      <div class="container relative z-1">
        <div class="w-6/12 max-lg:w-8/12 max-md:w-full">
          <div class="md:text-7xl leading-9 md:leading-[68px] md:mb-10 mb-1 text-4xl font-semibold">
            The Merciless <br /> Alpha <span class="text-primary">Killer</span>
          </div>
          <p class="mb-5 md:mb-[56px] md:text-lg text-sm font-normal italic text-[#BCBCBC] max-md:w-[75%]">
            “Murderer!” Everything changes for Anaiah Ross when she killed someone following her first unexpected shift into her wolf.
          </p>
        </div>
        <h1 class="mb-2 max-md:mb-1 max-md:text-sm">Read novels online anytime and anywhere</h1>
        <ul class="flex gap-3 justify-start items-center w-max max-w-full">
          <li>
            <a :href="appConfig.store.google" rel="nofollow" aria-label="play store">
              <nuxt-img
                  src="/img/gplay_b.svg"
                  width="170"
                  height="56"
                  alt="Google Play Store"
                  title="Download on the Google Play Store"
                  preload
              />
            </a>
          </li>
          <li>
            <a :href="appConfig.store.apple" rel="nofollow" aria-label="app store">
              <nuxt-img
                  src="/img/appstore_b.svg"
                  width="171"
                  height="56"
                  alt="App Store"
                  title="Download on the App Store"
                  preload
              />
            </a>
          </li>
          <li class="flex items-center max-md:hidden px-4 bg-[#060606] bg-opacity-50 rounded-lg h-[56px]">
            <img loading="lazy" alt="Scan QR" width="48" height="48" title="Scan QR" src="~/assets/img/qr_download.svg" class="mr-3 h-[48px]" />
            <span class="text-gray-200 w-[100px] text-xs leading-[14px]">Scan the qr-code to download the app</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="container py-8">
      <template v-for="(books, key) in data" :key="key">
        <lazy-f-card :title="lists[`top-${key}`]">
          <template v-if="![TRENDING_SLUG, EDITORS_CHOICE_SLUG].includes(key as string)" #actions>
            <nuxt-link :to="`/genres/${key}-novels`" class="p-2 border border-primary font-semibold flex items-center justify-between rounded text-primary">
              <span>See all</span>
              <icon name="fm:arrow-right" class="ml-2 text-2xl" />
            </nuxt-link>
          </template>
          <div v-if="key === TRENDING_SLUG" class="grid gap-4 lg:grid-cols-3 overflow-auto scrollbar-hidden max-lg:flex-col max-lg:gap-6">
            <lazy-book-detailed-card
                v-for="(book, index) in books"
                :book="book"
                :badge="index === 0 ? '1st' : (index === 1 ? '2nd' : (index === 2 ? '3rd' : null))"
                :mobile-bordered="false"
                :key="book.id"
            />
          </div>
          <div v-else class="flex gap-5 max-md:gap-3 overflow-auto scrollbar-hidden">
            <book-card
                v-for="book in books"
                :book="book"
                :key="book.id"
                :show-genre="EDITORS_CHOICE_SLUG === (key as string)"
            />
          </div>
        </lazy-f-card>

        <template v-if="key === TRENDING_SLUG">
          <div  class="mb-6 max-md:mb-2">
            <div class="flex justify-between items-center mb-6 max-md:mb-3">
              <h2 class="home-card__title text-3xl font-semibold max-md:text-2xl">Recent Reviews</h2>

              <nuxt-link to="/book-reviews" class="p-2 border border-primary font-semibold flex items-center justify-between rounded text-primary">
                <span>See all</span>
                <icon name="fm:arrow-right" class="ml-2 text-2xl" />
              </nuxt-link>
            </div>

            <div  class="grid grid-cols-4 gap-5 max-md:gap-3 max-lg:grid-cols-2">
              <review-preview v-for="review in reviews" :review="review" />
            </div>
          </div>

          <div  class="mb-6 max-md:mb-2">
            <div class="flex justify-between items-center mb-6 max-md:mb-3">
              <h2 class="home-card__title text-3xl font-semibold max-md:text-2xl">Recent Articles</h2>

              <nuxt-link to="/blog" class="p-2 border border-primary font-semibold flex items-center justify-between rounded text-primary">
                <span>See all</span>
                <icon name="fm:arrow-right" class="ml-2 text-2xl" />
              </nuxt-link>
            </div>

            <div  class="grid grid-cols-4 gap-5 max-md:gap-3 max-lg:grid-cols-2">
              <post-preview v-for="post in posts" :post="post" />
            </div>
          </div>
        </template>
      </template>

      <div v-if="pending" v-loading="pending" class="my-5"></div>
    </div>
  </div>
</template>

<style scoped>

</style>